import React from "react"

const Footer = () => {
  return (
    <>
      <footer>
        <p className="foot">
          ©CedPortfolio 2021 ⌲ Made with{" "}
          <span role="img" aria-label="heart-emoji">
            ❤️
          </span>
        </p>
      </footer>
    </>
  )
}

export default Footer
